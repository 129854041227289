import '../sass/project.scss';
import { Tooltip } from 'bootstrap';

document.addEventListener('DOMContentLoaded', function (event) {
  function toggleMenu(toggle) {
    const nav = document.getElementById('nav-bar'),
      bodypd = document.getElementById('body-pd'),
      headerpd = document.getElementById('header'),
      containers = document.getElementsByClassName('container');

    if (toggle && nav && bodypd && headerpd) {
      nav.classList.toggle('show-nav-bar');
      toggle.classList.toggle('bx-x');
      bodypd.classList.toggle('body-pd');
      headerpd.classList.toggle('header-pd');
      for (var i = 0; i < containers.length; i++) {
        containers[i].classList.toggle('container-blur');
      }
    }
  }

  const showNavbar = () => {
    const toggleLocalStorageVarName = 'menuToggle';
    const toggle = document.getElementById('header-toggle');
    const initialVal =
      localStorage.getItem(toggleLocalStorageVarName) === 'true';

    if (initialVal && window.innerWidth >= 769) {
      setTimeout(function () {
        toggleMenu(toggle);
      }, 100);
    }
    toggle.addEventListener('click', () => {
      toggleMenu(toggle);
      const localState =
        localStorage.getItem(toggleLocalStorageVarName) === 'true';
      localStorage.setItem(
        toggleLocalStorageVarName,
        localState ? 'false' : 'true',
      );
    });
  };

  showNavbar();

  /*===== LINK ACTIVE =====*/
  const linkColor = document.querySelectorAll('.nav_link');

  function colorLink() {
    if (linkColor) {
      linkColor.forEach((l) => l.classList.remove('active'));
      this.classList.add('active');
    }
  }

  linkColor.forEach((l) => l.addEventListener('click', colorLink));

  // Your code to run since DOM is loaded and ready
});
